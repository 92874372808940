import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import SongRowClosed from './SongRowClosed.js'

const songsByTotal = `
    query songsByTotal {
        songsByTotal(nomination_year: "2024", sortDirection: DESC, filter: {approved: {eq: true}}) {
            items {
                song
                album
                band
                donated_total
            }
        }
    }
`


const SongTableClosed = () => {
    const [songData, setSongData] = useState([]);

    const loadSongData = async() => {
/*         const { data } = await API.graphql(
            graphqlOperation(songsByTotal)
        );
 */        
        const { data } = await API.graphql({
            query: songsByTotal
        });

 //       console.log(data);

        setSongData(data?.songsByTotal?.items)
    }

    useEffect(() => {
        loadSongData();
    }, []); 
    

//    console.log(songData);

    const setListSongs = songData.slice(0,25);

 //   console.log(setListSongs);

    const remainderSongs = songData.slice(25);

//    console.log(remainderSongs);

    return (
      <section id="songTable" className="section">
        <div className='container'>
            <h2>The 2024 Ceol Kids song list!</h2>
            <p>On Friday May 24th 2024, the Ceol Kids band will play the top 25 tunes that you helped select for us with your donations! Time to use the link above to get your tickets in advance for the big show at Lee's Palace on May 24th! See you there!</p>
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th>Song</th>
                        <th>Band</th>
                        <th>Album</th>
                        <th>Donations</th>
                    </tr>
                </thead>
                <tbody>
                    {
                       setListSongs?.map((song, index) => <SongRowClosed songName={`${index + 1}. ${song.song}`} band={`${song.band}`} album={`${song.album}`} donationTotal={`${song.donated_total.toFixed(2)}`} rowkey={`${song.id}`} />)
                    }
 
                </tbody>
            </table>
            <h2>The Songs That Didn't Make It!</h2>
            <p>Oh no! These songs didn't make the cut this time. If one of your tunes is in this group, you'll have to try again next year. But there's still some great tunes on the setlist, make sure you buy your tickets for the show and see the ones that did make it in!</p>
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th>Song</th>
                        <th>Band</th>
                        <th>Album</th>
                        <th>Donations</th>
                    </tr>
                </thead>
                <tbody>
                    {
                       remainderSongs?.map((song, index) => <SongRowClosed songName={`${index + 26}. ${song.song}`} band={`${song.band}`} album={`${song.album}`} donationTotal={`${song.donated_total.toFixed(2)}`} rowkey={`${song.id}`} />)
                    }
 
                </tbody>
            </table>
       </div>
      </section>
    );
  }
  
  export default SongTableClosed;