import React from 'react';
import './App.css';
import Header from './component/Header';
import Jumbo from './component/Jumbo';
import SongTable from './component/SongTable';
import SongTableClosed from './component/SongTableClosed';
import Nominate from './component/Nominate';
import About from './component/About';
import Sponsors from './component/Sponsors';
import FinalCountdown from './component/FinalCountdown';

const closingDate = '2024-04-27T04:00:00Z';
const isClosed =  new Date().getTime() > new Date(closingDate).getTime();

console.log(isClosed);

class App extends React.Component {

  render() {
    return (
      <div className="App">
        <Header isClosed = {isClosed}/>
        {isClosed ? "" : <FinalCountdown closingDate = {closingDate} />}
        <Jumbo  isClosed = {isClosed}/>
        {isClosed ? <SongTableClosed /> : <SongTable closingDate = {closingDate}  />}
        {isClosed ? "" : <Nominate />}
        <About />
        {<Sponsors />}
      </div>
    );
  }
}

export default App;
