/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSongs = /* GraphQL */ `
  mutation CreateSongs(
    $input: CreateSongsInput!
    $condition: ModelSongsConditionInput
  ) {
    createSongs(input: $input, condition: $condition) {
      id
      band
      album
      song
      approved
      donation_link
      donation_link_id
      donated_total
      nominated_by
      nominated_on
      nomination_year
      donations {
        nextToken
        __typename
      }
      promotions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSongs = /* GraphQL */ `
  mutation UpdateSongs(
    $input: UpdateSongsInput!
    $condition: ModelSongsConditionInput
  ) {
    updateSongs(input: $input, condition: $condition) {
      id
      band
      album
      song
      approved
      donation_link
      donation_link_id
      donated_total
      nominated_by
      nominated_on
      nomination_year
      donations {
        nextToken
        __typename
      }
      promotions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSongs = /* GraphQL */ `
  mutation DeleteSongs(
    $input: DeleteSongsInput!
    $condition: ModelSongsConditionInput
  ) {
    deleteSongs(input: $input, condition: $condition) {
      id
      band
      album
      song
      approved
      donation_link
      donation_link_id
      donated_total
      nominated_by
      nominated_on
      nomination_year
      donations {
        nextToken
        __typename
      }
      promotions {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDonation = /* GraphQL */ `
  mutation CreateDonation(
    $input: CreateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    createDonation(input: $input, condition: $condition) {
      id
      donation_amount
      donated_by
      createdAt
      stripe_id
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsDonationsId
      __typename
    }
  }
`;
export const updateDonation = /* GraphQL */ `
  mutation UpdateDonation(
    $input: UpdateDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    updateDonation(input: $input, condition: $condition) {
      id
      donation_amount
      donated_by
      createdAt
      stripe_id
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsDonationsId
      __typename
    }
  }
`;
export const deleteDonation = /* GraphQL */ `
  mutation DeleteDonation(
    $input: DeleteDonationInput!
    $condition: ModelDonationConditionInput
  ) {
    deleteDonation(input: $input, condition: $condition) {
      id
      donation_amount
      donated_by
      createdAt
      stripe_id
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsDonationsId
      __typename
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion(
    $input: CreatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    createPromotion(input: $input, condition: $condition) {
      id
      promoted_by
      message
      createdAt
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsPromotionsId
      __typename
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion(
    $input: UpdatePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    updatePromotion(input: $input, condition: $condition) {
      id
      promoted_by
      message
      createdAt
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsPromotionsId
      __typename
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion(
    $input: DeletePromotionInput!
    $condition: ModelPromotionConditionInput
  ) {
    deletePromotion(input: $input, condition: $condition) {
      id
      promoted_by
      message
      createdAt
      song {
        id
        band
        album
        song
        approved
        donation_link
        donation_link_id
        donated_total
        nominated_by
        nominated_on
        nomination_year
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      songsPromotionsId
      __typename
    }
  }
`;
