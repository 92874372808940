/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "addStripeProduct",
            "endpoint": "https://3qfb4q4hzf.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        },
        {
            "name": "stripeWebhook",
            "endpoint": "https://ja4ugdm32a.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://w4lyhrztl5ac5pmt7jpoq76uyy.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ca-central-1:9c880710-9b1d-43eb-a0c4-c080a1f28632",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_TxE6SRS6a",
    "aws_user_pools_web_client_id": "7f87k52c99alcav1r9gm4tbr7r",
    "oauth": {
        "domain": "ceolkidsreactmigratif4a874ea-f4a874ea-prod.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3001/",
        "redirectSignOut": "http://localhost:3001/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
