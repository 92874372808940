import React from 'react';
import { useState } from "react";
import ArrowUp from './ArrowUp';
import NewSong from './NewSong';

const SongRow = ({ songName, band, album, donationTotal, paymentLink, rowkey, createdAt, updatedAt }) => {
   // console.log(songName);
   // console.log(paymentLink);

   const clipboardImage = 
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
   <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
   <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
</svg>;


const clipboardCheckedImage = 
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
  <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
</svg>;


    const [bttnText, setBttnText] = useState(clipboardImage);

    const handleClick = () => {
    window.open(paymentLink, '_blank');
};

    const handleClickCopy = () => {
            navigator.clipboard
              .writeText(paymentLink)
              .then(() => {
                setBttnText(clipboardCheckedImage);
                setTimeout(function () {
                  setBttnText(clipboardImage);
                }, 3000);
              })
              .catch((err) => {
                console.log(err.message);
              });
          };


 //   const newSong =  new Date().getTime() > new Date(closingDate).getTime();
    const TWENTY_FOUR_HOURS_IN_MS = 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();
    const dateTimeTwentyFourHoursAgo = NOW_IN_MS - TWENTY_FOUR_HOURS_IN_MS;



    const newSong = new Date(createdAt).getTime() >= new Date(dateTimeTwentyFourHoursAgo).getTime();
    const newDonation = (new Date(updatedAt).getTime() >= new Date(dateTimeTwentyFourHoursAgo).getTime()) && (donationTotal > 0);
       
    return (

        <tr key={rowkey} className = "">
            <td>{songName}  <NewSong isNew={newSong} /></td>
            <td>{band}</td>
            <td>{album}</td>
            <td>${donationTotal} <ArrowUp isNew={newDonation} /></td>
            <td><button className='btn btn-sm btn-primary' onClick={handleClick}>Donate!</button></td>
            <td className='text-center'><button className='btn btn-sm btn-primary' onClick={handleClickCopy}>{bttnText}</button></td>
        </tr>
    );
}

export default SongRow;