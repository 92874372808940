import React from 'react';

// style='height:auto; width:auto; max-height:30px;'

const ArrowUp = ( isNew ) => {

    console.log(isNew.isNew);

  return (
    <span>
        {!isNew.isNew ? "" : <img className = 'new-song-image' src='/images/label_blue_new.png' border='0' data-toggle="tooltip" title="Nominated less than 24 hours ago!" />}
    </span>
  );
}

export default ArrowUp;



