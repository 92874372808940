import React from 'react';
import CountdownTimer from './CountdownTimer';


const FinalCountdown = ( closingDate ) => {

  const donationEndDate = '2023-04-27T04:00:00Z';

  return (
    <div className='container'>
      <CountdownTimer targetDate={closingDate.closingDate} />
    </div>
  );
}

export default FinalCountdown;