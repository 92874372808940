import React from 'react';

// sponsorWidth="col-lg-4" sponsorLink="https://rxmusic.com/" sponsorLogo="/images/RX-Music-Logo.jpg" sponsorAlt="RX MUsic"

const SponsorCard = ({ sponsorWidth, sponsorLink, sponsorLogo, sponsorAlt }) => {
    return (
        <div className={ sponsorWidth } >
            <a href={ sponsorLink } target="_blank" rel="noreferrer">
                <img className="img-responsive sponsor_logo text-center"  src={ sponsorLogo } alt={ sponsorAlt } ></img>
            </a>
        </div>

    );
}

export default SponsorCard;