import React from 'react';
import SectionHeader from './SectionHeader.js';
import SponsorCard from './SponsorCard.js';





const Sponsors = () => {
    return (
      <section id="sponsors" className="section">
        <div className='container'>
            <SectionHeader headerImage="/images/sponsor-circle.png" headerTitle="Our 2024 Sponsors" headerBody={["Ceol Kids wants to thank the generosity of our Sponsors. Sponsorship contributions help pay for things like venue costs, rehearsal space, and web hosting. Our event attracts many people who love and support music. If you would like to help support the upcoming 2024 Ceol Kids event, ", <a href='/files/Ceol-Kids-Sponsorship-2024.pdf' download >Download our Sponsorship package</a>, " or get in touch with us via ", <a href='mailto:sponsorships@ceolkids.com'>sponsorships@ceolkids.com</a> ]} />
        </div>

       { <div  className='container'>
            <h2 className='text-center sponsor_head'>Silver Sponsors</h2>
{/*             <SponsorCard sponsorWidth="col-lg-4" sponsorLink="https://rxmusic.com/" sponsorLogo="/images/RX-Music-Logo.jpg" sponsorAlt="RX MUsic" />
            <SponsorCard sponsorWidth="col-lg-4" sponsorLink="https://www.fireballwhisky.com/" sponsorLogo="/images/fireball-logo.png" sponsorAlt="Fireball Whisky" />
  */}           <SponsorCard sponsorWidth="col-lg-4" sponsorLink="https://www.long-mcquade.com/" sponsorLogo="/images/logo-stacked.png" sponsorAlt="Long & McQuade" />
       </div>}
       <div  className='container'>
            <h2 className='text-center'>Bronze Sponsors</h2>
            {/* <SponsorCard sponsorWidth="col-lg-3 p-6" sponsorLink="https://www.kilomg.com/" sponsorLogo="/images/KilometerLogo.png" sponsorAlt="Kilometre Music Group" /> */}
            <SponsorCard sponsorWidth="col-lg-3 p-6" sponsorLink="https://www.flashreproductions.com/ " sponsorLogo="/images/FLASH-C.png" sponsorAlt="Flash Reproductions" />
       </div>
{/*         <div  className='container'>
            <h2 className='text-center'>Official Roadie Status</h2>
            <SponsorCard sponsorWidth="col-lg-3 p-12" sponsorLink="https://ciut.fm/" sponsorLogo="/images/CIUT_logo.png" sponsorAlt="CIUT" />
       </div>
 */}      </section>
    );
  }
  
  export default Sponsors;