import React from 'react';


const About = () => {
    return (
      <section id="about" className="section">
        <div className='container'>
        <div className="jumbotron">
            <img className = "left-block intro-image" src = "images/story-image-2015.png" alt="Our Story!" width="266" height="266" />
		    <h2>Our Story!</h2>
		    <p>Ceol Kids is a diverse group of musicians who came together for a surprise party a few years ago. The plan was to take 30 random songs from the ipod of the guest of honour, and perform them as best as possible. When it was all said and done, we had 30 different musicians contributing everything from fiddle, horns, and yes, even accordion on songs from a wildly divergent range of bands including No Doubt, The Who, Bon Jovi, Spirit of the West, and maybe just a little Neil Diamond...

It was such an entertaining night for both audience and band members alike, that all involved decided that it could not be put to rest. Instead of a closed surprise party, we decided that we could put our talents toward raising money for a local charity that we all believed in. The Regent Park School of Music was very quickly decided upon. The name Ceol Kids comes from the Gaelic word for 'music' (pronounced kyool).</p>
       </div>
       <h2>About Community Music Schools of Toronto</h2>
                
                <p>Formerly known as the Regent Park School of Music, Community Music Schools of Toronto aims to provide high quality, affordable music education to underprivileged youth in Regent Park, Jane Finch and other areas of the City of Toronto. Community Music Schools of Toronto is one of Toronto's most diverse music schools, with a mandate to provide music education to youth who might not otherwise have the opportunity. Located in the swanky new Daniels Spectrum, they also operate satellite programs in other high need areas of the city where children receive extra-curricular music lessons in the safe and familiar space of their own community school. <a href="http://communitymusic.org/" >Visit their website!</a></p>

                <h2>Donation information</h2>
                
                <p>All donations made in support of songs (net of processing charges - $0.30 + 2.9% of donation) will be passed on to Community Music Schools of Toronto. All major credit cards are welcome. Although we are not enforcing a strict donation minimum, please try and donate in increments of $5 or more to minimize the impact that fees have on the donation amount. Event and website hosting costs will be deducted from ticket sales and the remainder will be donated to Community Music Schools of Toronto. Please note that Ceol Kids is not affiliated with Community Music Schools of Toronto in any way. We are unable to issue tax receipts for your donation. Thank you for your understanding!</p>

                <h2 id="song_suggestion">Song Suggestion Rules and Information</h2>
                
				<p>At the end of the bidding period (ending April 26th at 11:59:59pm (EDT) - that's Friday night/Saturday morning!), the top 25 songs are the tunes that will be played at the show.  Note that to promote a more diverse setlist, we will limit the final list to no more than two songs from any particular band. If three or more songs in the top 25 are from the same band, all but the top two will be disqualified. Also, we are only allowing a maximum of three songs from last year's setlist onto this show. For new songs, we'll need to verify that we can actually find the song (an original song from your high school band isn't going to cut it - even if we went to your high school!). Also we do reserve the right to reject songs with no further reason given.</p>

                <h2 id="email">Email address information</h2>
                
                <p>Ceol Kids collects email addresses for ticket sales and donation processing. We use this information to issue confirmation codes for ticket purchases and contact you in case of clarification or updates to song information. We will not give your email address out to anyone else, or contact you regading anything other than Ceol Kids related info. (including, but not limited to: show information/updates, tickets remaining, deadline reminders for song suggestions and/or donations, etc.) If you do not wish to receive this information, please send an email to info@ceolkids.com with 'UNSUBSCRIBE' in the subject line.</p>          
       </div>
      </section>
    );
  }
  
  export default About;