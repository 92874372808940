import React from 'react';

const SongRowClosed = ({ songName, band, album, donationTotal, rowkey}) => {
   // console.log(songName);
   // console.log(paymentLink);





    return (

        <tr key={rowkey}>
            <td>{songName}</td>
            <td>{band}</td>
            <td>{album}</td>
            <td>${donationTotal}</td>
        </tr>
    );
}

export default SongRowClosed;