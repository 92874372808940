import React from 'react';
import { useCountdown } from '../hooks/useCountdown';
import DateTimeDisplay from './DateTimeDisplay';


const ExpiredNotice = () => {
    return (
      <div className="expired-notice">
        <span>Donations Closed!</span>
        <p>Time's up! Refresh your browser to see the final list.</p>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="show-counter">
        <div className='countdown-link'>
          <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 1} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={days == 0 && hours <= 8} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={days == 0 && hours <= 8} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={days == 0 && hours <= 8} />
        </div>
      </div>
    );
  };
  
  

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
