import React from 'react';

const PostItem = ({ postHeader, postedBy, postedOn, postBody }) => {
    return (
/*       <li className="product-item">
        <img className="product-image" src={`images/products/${dynamicSlug}`} alt={`${productName} - Product`} />
        <h2 className="product-name">{productName}</h2>
      </li>
 */
 //       <div className='row'>
            <div className='col-lg-4'>
                <h2>{postHeader}</h2>
                <p><small>posted by {postedBy} on {postedOn}</small></p>
                <p>{postBody}</p>
            </div>
 //       </div>

    );
}

export default PostItem;