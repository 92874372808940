import React from 'react';



const Header = ( isClosed ) => {
 // console.log(isClosed.isClosed);
  return (
    <header id="home" className="container">
          <div className="center-block img-responsive"  width = "1000" height="413">
            <a href="#home"><img src="images/ceolkids_masthead_web_1000.jpg" alt="Ceol Kids" /></a>
          </div>
      <nav className="navbar navbar-default" role="navigation">
        <div className="navbar-header navbar-static-top">
          <ul className="nav navbar-nav">
            <li key="home"><a href="#home">Home</a></li>
            
            {isClosed.isClosed ? "" : <li key="nominate"><a href="#nominate">Nominate</a></li>}
            <li key="about"><a href="#about">About</a></li>
            <li key="sponsors"><a href="#sponsors">Sponsors</a></li>
            {<li key="program"><a href="files/2024_Program.pdf">2024 Show Program</a></li>}
            {<li key="tickets"><a href="https://www.showclix.com/event/ceol-kids-fundraiser-for-the-community-music-schools-of-toronto">Tickets</a></li>}
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default Header;