import React from 'react';

const SectionHeader = ({ headerImage, headerTitle, headerBody }) => {
    return (

                <div className="jumbotron"> 
                    <h2 className="headline">
                    <img className='left-block intro-image' src={ headerImage } alt="Image of Rock Show" width="266" height="266"></img>
                    <div>{ headerTitle }</div>
                    </h2>
                    <p>{ headerBody }</p>
                </div>

      );
}

export default SectionHeader;